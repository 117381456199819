.content {

    flex-grow: 1;
    flex-direction: column;
    display: flex;
}


.toolbar {

    display: flex;
    justify-content: flex-end;
    margin: 2rem 6rem 3rem 0rem;
    
}

.downloadimage{

    padding: 0.8rem;
    border-radius: 1rem;
    background-color: #9900EF;
}


.center {
    display: flex;
    align-items: center;
    justify-content: center;
    
   


}



.design-frame1 {
    box-sizing: border-box;
    max-width: 500px;
    max-height: 420px;

}

.design-frame2 {
    box-sizing: border-box;
    max-width: 500px;
    max-height: 420px;

}